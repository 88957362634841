import { FC, useState, useEffect, memo } from 'react';
import Script from 'next/script';
import waitForGlobal from '@utils/wait-for-global';
import { ZENDESK_SCRIPT_SRC } from '@constants';

// TODO ZD - once the feature flag is no longer needed, kill these constants:
// ZENDESK_WIDGET_CONFIG,
// ZENDESK_WIDGET_CONFIG_LG_BUILD,
// ZENDESK_WIDGET_CONFIG_SM_BUILD,
// ZENDESK_CHAT_KEY

type ZeSettings = {
  webWidget: {
    color: { theme: string };
    zIndex?: number;
    offset?: {
      horizontal?: string;
      vertical?: string;
      mobile?: {
        horizontal?: string;
        vertical?: string;
      };
    };
    launcher: {
      label: {
        '*': string;
        en: string;
        de: string;
        fr: string;
        it: string;
        nl: string;
        es?: string;
      };
    };
  };
  cookies?: boolean;
};

declare global {
  interface Window {
    zESettings?: ZeSettings;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE?: any;
  }
}

type Props = {
  lang?: string;
};

const ZendeskChatFlowbuilder: FC<Props> = ({ lang }) => {
  const [hasInitialized, setHasInitialized] = useState(false);

  // TODO ZD - the identify method no longer exists
  // need to implement a jwt based login flow
  // see https://support.zendesk.com/hc/en-us/articles/4411666638746
  // and https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/#authentication

  // update widget locale if language changes
  useEffect(() => {
    if (hasInitialized && lang) {
      waitForGlobal('zE', () => {
        window.zE('messenger:set', 'locale', lang);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  // TODO ZD - the updateSettings method no longer exists
  // need to implement a custom launcher to accomplish this behavior
  // see https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/messaging-web-widget-qs/

  return process.env.NEXT_PUBLIC_ZENDESK_CHAT_KEY ? (
    <Script
      id="ze-snippet"
      src={`${ZENDESK_SCRIPT_SRC}${process.env.NEXT_PUBLIC_ZENDESK_CHAT_KEY}`}
      strategy="lazyOnload"
      onLoad={() => {
        setHasInitialized(true);

        if (typeof window !== 'undefined') {
          if (lang) {
            waitForGlobal('zE', () => {
              window.zE('messenger:set', 'locale', lang);
            });
          }
        }
      }}
    />
  ) : null;
};

export default memo(ZendeskChatFlowbuilder);
